import { useState, useEffect } from 'react'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import RadioButton from 'Components/Forms/RadioButton/RadioButton'

function ModalityStep() {

  const navigate = useNavigate()
  const [step, setStep, newOffer, setNewOffer, filters, formulaire] = useOutletContext();
  const { id } = useParams();

  const [errors, setErrors] = useState({
    idContract: null,
    tags: null,
    availabilityDate: null,
    salary: null,
    tjm: null
  })

  const handleContract = (e) => {
    setErrors(s => ({ ...s, idContract: null }))
    if (newOffer.idContract.includes(e.target.value)) {
      //Le client dé-selection un contract
      //On enlève la value du state
      setNewOffer(prev => ({ ...prev, idContract: prev.idContract.filter(x => x !== e.target.value) }))
      //On erase les données du salaire et du tjm si ils ont été intilisé
      if (e.target.value === "/api/contracts/6") {
        setNewOffer(prev => ({ ...prev, tjm: null }))
      }
      if (["/api/contracts/2", "/api/contracts/3", "/api/contracts/4"].includes(e.target.value)) {
        setNewOffer(prev => ({ ...prev, salaire: null }))
      }
    } else {
      setNewOffer(prev => ({ ...prev, idContract: [...prev.idContract, e.target.value] }))
    }
  }

  const handleInterventionType = (e) => {
    setErrors(s => ({ ...s, tags: null }))
    if (newOffer.tags.filter(item => item.tag === e.target.value).length > 0) {
      setNewOffer(prev => ({ ...prev, tags: prev.tags.filter(x => x.tag !== e.target.value) }))
    } else {
      setNewOffer(prev => ({
        ...prev, tags: [...prev.tags, {
          offre: `api/offres/${id}`,
          tag: e.target.value,
          level: 1
        }]
      }))
    }
  }

  const availabilityHandler = (e) => {
    const avaibility = e.target.value
    setErrors(s => ({ ...s, availability: null }))
    if (avaibility == "immédiate") {
      setNewOffer(prev => ({ ...prev, availability: [e.target.value], availabilityDate: "" }))

    } else {
      setNewOffer(prev => ({ ...prev, availability: [e.target.value] }))
    }
  }

  const startDateHandler = (e) => {
    const value = e.target.value
    const startDate = new Date(value).toISOString()
    setNewOffer(prev => ({ ...prev, availabilityDate: startDate }))
  }

  const localisationHandler = (e) => {
    const localisation = e.target.value
    setErrors(s => ({ ...s, localisation: null }))
    setNewOffer(prev => ({ ...prev, localisation: localisation }))
  }

  const salaryHandler = (e) => {
    const salary = parseInt(e.target.value)
    setNewOffer(prev => ({ ...prev, salaire: salary }))
    setErrors(s => ({ ...s, salary: null }))
  }
  const jtmHandler = (e) => {
    const tjm = parseInt(e.target.value)
    setNewOffer(prev => ({ ...prev, tjm: tjm }))
    setErrors(s => ({ ...s, tjm: null }))
  }

  const remoteHandler = (e) => {
    setErrors(s => ({ ...s, remote: null }))
    setNewOffer(prev => ({ ...prev, remote: e.target.value }))
  }

  const remoteWorkTime = (e) => {
    setErrors(s => ({ ...s, workTime: null }))
    if (newOffer.workTime.includes(e.target.value)) {
      setNewOffer(prev => ({ ...prev, workTime: prev.workTime.filter(x => x !== e.target.value) }))
    } else {
      setNewOffer(prev => ({ ...prev, workTime: [...prev.workTime, e.target.value] }))
    }
  }

  const next = () => {

    if (validation()) {
      setStep(prev => ({ ...prev, modalite: true }))
      navigate(`/mon-espace/mes-offres/${formulaire}/profil`)
    }
  }

  const prev = () => {

    if (validation()) {
      setStep(prev => ({ ...prev, modalite: true }))
      navigate(`/mon-espace/mes-offres/${formulaire}`)
    }
  }

  const validation = () => {
    let flag = true;
    if (newOffer.idContract.length === 0) {
      setErrors(s => ({ ...s, idContract: "Veuillez remplir ce champ" }))
      flag = false;
    }

    if (!newOffer.tags.some(t => t.tag === "api/tags/10" || t.tag === "api/tags/11" || t.tag === "api/tags/12")) {
      setErrors(s => ({ ...s, tags: "Veuillez remplir ce champ" }))
      flag = false;
    }

    if (newOffer.availability.includes("Date connue") && newOffer.availabilityDate === "") {
      setErrors(s => ({ ...s, availabilityDate: "Veuillez remplir ce champ" }))
      flag = false;
    }

    if (newOffer.availability.length === 0) {
      setErrors(s => ({ ...s, availability: "Veuillez remplir ce champ" }))
      flag = false;
    }

    if (newOffer.localisation === "") {
      setErrors(s => ({ ...s, localisation: "Veuillez remplir ce champ" }))
      flag = false;
    }

    if (newOffer.remote === "") {
      setErrors(s => ({ ...s, remote: "Veuillez remplir ce champ" }))
      flag = false;
    }

    if (newOffer.workTime.length === 0) {
      setErrors(s => ({ ...s, workTime: "Veuillez remplir ce champ" }))
      flag = false;
    }

    return flag
  }



  return (
    <div className='p-4'>
      <div className='grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6'>
        <div className='col-span-6 lg:col-span-3'>
          {errors.idContract && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.idContract}</span>}
          <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Contrat recherché*</label>
          <div className='grid grid-cols-2'>
            {
              filters ?
                filters.contrat.map((el, key) => (
                  <div className='flex gap-1 items-center'>
                    <input onChange={(e) => handleContract(e)} checked={newOffer.idContract.includes(`/api/contracts/${el.id}`)} value={`/api/contracts/${el.id}`} className='cursor-pointer' type={"checkbox"} key={el.id} id={el.id} />
                    <label className='cursor-pointer' htmlFor={el.id}>{el.name}</label>
                  </div>
                ))
                :
                null
            }

          </div>
        </div>
        <div className='col-span-6 lg:col-span-3'>
          {errors.tags && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.tags}</span>}
          <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Type d'intervention*</label>
          <div className='grid grid-cols-2 lg:grid-cols-1 '>
            {
              filters ?
                filters.intervention.filter(el => el.id === 10 || el.id === 11 || el.id === 12).map((el, key) => (
                  <div className='flex gap-1 items-center'>
                    <input onChange={handleInterventionType}
                      checked={newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`).length > 0}
                      value={`api/tags/${el.id}`}
                      className='cursor-pointer'
                      type={"checkbox"}
                      key={el.id}
                      id={el.id} />
                    <label className='cursor-pointer' htmlFor={el.id}>{el.name}</label>
                  </div>
                ))
                :
                null
            }
          </div>
        </div>
        <div className='col-span-6 md:col-span-3'>
          <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Localisation*</label>
          <select id="department" onChange={localisationHandler} name="department" className='w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none'>
            <option hidden></option>
            {
              filters ?
                filters.localisation.map((loc, key) => (
                  <option key={key} selected={newOffer.localisation.includes(loc.nom)} value={loc.nom} >{loc.nom}</option>
                ))
                :
                null
            }
          </select>
          {errors.localisation && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.localisation}</span>}
        </div>
        <div className='col-span-6 md:col-span-3'>
          <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Disponibilité*</label>
          <select id="" onChange={availabilityHandler} name="" className='w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none'>
            <option hidden></option>
            <option selected={newOffer.availability.includes("Immédiate")} value="Immédiate">Immédiate</option>
            <option selected={newOffer.availability.includes("Date connue")} value="Date connue" >Date connue</option>
          </select>
          {errors.availability && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.availability}</span>}
          {
            newOffer.availability.includes("Date connue") ?
              <>
                <input value={newOffer.availabilityDate && newOffer.availabilityDate !== "" ? newOffer.availabilityDate.split("T")[0] : ""} onChange={startDateHandler} name='dateEnd' id='dateEnd' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2 mt-2' type="date" />
                {errors.availabilityDate && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.availabilityDate}</span>}
              </>
              :
              null
          }
        </div>
        {
          newOffer.idContract.includes("/api/contracts/3") || newOffer.idContract.includes("/api/contracts/4") || newOffer.idContract.includes("/api/contracts/2") ?
            <div className='col-span-6 md:col-span-3'>
              <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Salaire annuel brut</label>
              <input value={newOffer.salaire ? newOffer.salaire : ""} onChange={salaryHandler} className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="number" />
              {errors.salary && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.salary}</span>}
            </div>
            :
            null
        }
        {
          newOffer.idContract.includes("/api/contracts/6",) ?
            <div className='col-span-6 md:col-span-3'>
              <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Taux journalier moyen</label>
              <input value={newOffer.tjm ? newOffer.tjm : ""} onChange={jtmHandler} className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="number" />
              {errors.tjm && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.tjm}</span>}
            </div>
            :
            null
        }
        <div className='sm:col-span-3'>
          <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Présentiel / télétravail*</label>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-col md:flex-row gap-1'>
              {["Présentiel", "Hybride", "Télétravail"].map((type) => {
                return <label htmlFor={type} className={`inline-block px-4 py-1 border border-[#5B9ECF] rounded-[5px] text-center hover:cursor-pointer hover:bg-[#5B9ECF] hover:text-white ${newOffer.remote && newOffer.remote === type ? "bg-[#5B9ECF] text-white" : "bg-white text-[#5B9ECF]"}`} >
                  <input
                    type="radio"
                    id={type}
                    name={type}
                    value={type}
                    checked={newOffer.remote && newOffer.remote === type}
                    onChange={remoteHandler}
                    className='hidden'
                  />
                  <span className="font-medium">{type}</span>
                </label>
              })}
            </div>
            {errors.remote && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.remote}</span>}
          </div>
        </div>
        <div className='sm:col-span-3'>
          <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'> Temps plein / Temps partiel / à l'heure*</label>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-col md:flex-row gap-1'>
              {["Temps plein", "Temps partiel", "À l'heure"].map((type) => {
                return <label htmlFor={type} className={`inline-block px-4 py-1 border border-[#5B9ECF] rounded-[5px] text-center hover:cursor-pointer hover:bg-[#5B9ECF] hover:text-white ${newOffer.workTime && newOffer.workTime.includes(type) ? "bg-[#5B9ECF] text-white" : "bg-white text-[#5B9ECF]"}`} >
                  <input
                    type="checkbox"
                    id={type}
                    name={type}
                    value={type}
                    checked={newOffer.workTime && newOffer.workTime.includes(type)}
                    onChange={remoteWorkTime}
                    className='hidden'
                  />
                  <span className="font-medium">{type}</span>
                </label>
              })}
            </div>
            {errors.workTime && <span className='col-span-2 text-red-500 text-xs font-bold'>{errors.workTime}</span>}
          </div>
        </div>

        <div className='col-span-6 flex justify-between'>
          <SecondaryButton type={"submit"} text={"Précedent"} onClick={() => prev()} />
          <PrimaryButton type={"submit"} text={"Suivant"} onClick={() => next()} />
        </div>
      </div>
    </div>
  )
}

export default ModalityStep