import { useEffect, useState } from 'react'
import CreateOrEditOfferNav from './CreateOrEditOfferNav'
import { Outlet, useOutletContext } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import jwt from "jwt-decode";

export default function EditOffer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = cookie.get("token");
  const decodedToken = jwt(token);
  const [filters] = useOutletContext();
  const formulaire = `modification-offre/${id}`;
  const [step, setStep] = useState({
    intituler: false,
    modalite: false,
    profile: false,
    description: false
  })

  const navigateHandler = (e) => {
    navigate(`/mon-espace/mes-offres/nouvelle-offre/${e.target.value}`)
  }

  const [newOffer, setNewOffer] = useState({
    intitule: "",
    idContract: [],
    tags: [],
    availability: ["Immédiate"],
    availabilityDate: "",
    salaire: null,
    tjm: null,
    entrepriseOffre: "",
    description: "",
    localisation: [],
    idTimeexperience: [],
    idGrade: [],
    remote: ""
  })

  function structureDate(data) {
    console.log(data)
    return ({
      intitule: data.intitule,
      idContract: data.id_contract.map((contrat) => {
        return (`/api/contracts/${contrat.id}`)
      }),
      tags: data.tags.map((tag) => {
        return {
          offre: `api/offres/${id}`,
          tag: `api/tags/${tag.tag.id}`,
          level: tag.level
        }
      }),
      availability: data.availability,
      availabilityDate: data.availability_date ? data.availability_date : null,
      salaire: data.salaire ? data.salaire : null,
      tjm: data.jtm ? data.jtm : null,
      entrepriseOffre: data.entreprise_offre,
      description: data.description,
      localisation: data.localisation,
      idTimeexperience: data.id_timeexperience.map((timeexperience) => {
        return (`api/time_experiences/${timeexperience.id}`)
      }),
      idGrade: data.id_grade.map((grade) => {
        return (`api/grades/${grade.id}`)
      }),
      remote: data.remote,
      workTime: data.workTime ? data.workTime : []
    })
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `client/offre/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log(response)
        setNewOffer(structureDate(response.data))
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error === "L'id entré est incorrect") {
            navigate("/mon-espace")
          }
        }
      });
  }, [])

  return (
    <div className='flex gap-4 p-6 md:items-start flex-col md:flex-row xl:justify-center'>
      <CreateOrEditOfferNav step={step} />
      <select onChange={navigateHandler} className='md:hidden w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none' name="" id="">
        <option value="">Intitulé</option>
        <option value="modalite">Modalité</option>
        <option value="profil">Profil</option>
        <option value="description">Description</option>
        <option selected value="recapitulatif">Récapitulatif</option>
      </select>
      <div className='bg-white flex-1 lg:max-w-[800px]'>
        <Outlet context={[step, setStep, newOffer, setNewOffer, filters, formulaire]} />
      </div>
    </div>
  )
}