import React from 'react';
import { InlineWidget } from "react-calendly";
import Fleche from 'assets/svg/fleche'
import { Link } from 'react-router-dom';

function RendezVous() {
  return (
    <>
      <div className='flex flex-col'>
        <div className=''>
          <InlineWidget url="https://calendly.com/contact-cabinet-marceau/15min?month=2024-08" />
        </div>
        <Link to="/devenir-consultant">
          <div className='flex items-center mt-10 gap-2.5  mx-[10px] xl:mx-[300px] md:mx-[30px] lg:mx-[99px] 2xl:mx-[848px]'>
            <Fleche color={"#5B9ECF"} classProps={"rotate-[180deg] "} />
            <p className='text-lg text-[#5B9ECF]'>Retour</p>
          </div>
        </Link>
      </div>

    </>
  )
}

export default RendezVous;
