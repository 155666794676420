import React, { useContext, useState } from 'react'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { MdChecklist } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { FaHourglassStart } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";
import { FaUserTie } from "react-icons/fa";
import { VscRemoteExplorer } from "react-icons/vsc";
import { MdOutlineTimelapse } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import AvaibilityBadge from "Components/AvaibilityBadge/AvaibilityBadge";

import parse from "html-react-parser";

import axios from "axios";
import cookie from "js-cookie";
import { format } from "date-fns";
import { fr } from 'date-fns/locale'
import TagLevel from 'Components/TagLevel/TagLevel';

function SummaryStep() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = cookie.get("token");
  const [isLoading, setIsLoading] = useState(false)
  const { alert } = useContext(AlertMessageContext);
  const [errors, setErrors] = useState([])
  const [step, setStep, newOffer, setNewOffer, filters, formulaire] = useOutletContext();

  function structureData(create = false) {
    return ({
      "intitule": newOffer.intitule,
      "description": newOffer.description,
      "jtm": newOffer.tjm,
      "entrepriseOffre": newOffer.entrepriseOffre,
      "salaire": newOffer.salaire,
      "availability": newOffer.availability,
      "localisation": [newOffer.localisation],
      "idGrade": newOffer.idGrade,
      "tags": create ? [] : newOffer.tags,
      "tagsCustom": create ? newOffer.tags : [],
      "idContract": newOffer.idContract,
      "idTimeexperience": newOffer.idTimeexperience,
      "availabilityDate": newOffer.availabilityDate !== "" ? newOffer.availabilityDate : null,
      "remote": newOffer.remote,
      "workTime": newOffer.workTime
    });
  }

  function publicationOffre() {
    if (validation()) {
      setIsLoading(true);
      axios.post(process.env.REACT_APP_API + "client/offre/create",
        structureData(true),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          console.log(res)
          setIsLoading(false);
          alert.successAlert("Votre opportunité a été publiée avec succès !");
          navigate(`/mon-espace/mes-offres/detail/${res.data.id}`)
        }).catch((error) => {
          setIsLoading(false);
          alert.errorAlert("Une erreur s'est produite lors de la publication de votre opportunité !");
        })
    }
  }

  function saveModif() {
    if (validation()) {
      setIsLoading(true);
      axios.patch(
        process.env.REACT_APP_API + `client/offre/modify/${id}`,
        structureData(),
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`
        },
        })
        .then((res) => {
          console.log(res)
          setIsLoading(false);
          alert.successAlert("Les modifications ont été enregistrées avec succès !");
          navigate(`/mon-espace/mes-offres/detail/${id}`)
        }).catch((error) => {
          console.log(error)
          setIsLoading(false);
          alert.errorAlert("Une erreur s'est produite lors de la modification de l'opportunité !");
        })
    }
  }

  function validation() {
    var flag = true;
    var errors = [];

    if (newOffer.intitule === "") {
      errors.push("Veuillez renseigner l'intitulé de l'opportunité")
      flag = false;
    }
    
    if (newOffer.entrepriseOffre === "") {
      errors.push("Veuillez renseigner le nom de l'entreprise")
      flag = false;
    }

    if (newOffer.idContract.length < 0) {
      errors.push("Veuillez indiqué les contrats recherchés")
      flag = false;
    }

    if (!newOffer.tags.some(t => t.tag === "api/tags/10" || t.tag === "api/tags/11" || t.tag === "api/tags/12")) {
      errors.push("Veuillez indiquer le type d'intervention")
      flag = false;
    }

    if (newOffer.localisation === "") {
      errors.push("Veuillez indiquer la localisation")
      flag = false;
    }

    if (newOffer.availability.includes("Date connue") && newOffer.availabilityDate === "") {
      errors.push("Veuillez indiquer la disponibilité de votre opportunité")
      flag = false;
    }

    if (newOffer.availability.includes("")) {
      errors.push("Veuillez indiquer la date de disponibilité")
      flag = false;
    }

    var flagsTags = { "secteur": false, "fonctionnelle": false, "informatique": false, "linguistique": false }
    newOffer.tags.map((tagOffer) => {
      ["secteur", "fonctionnelle", "informatique", "linguistique"].map((categorie) => {
        const tagFound = filters[categorie].some((tagFilter) => { return `api/tags/${tagFilter.id}` === tagOffer.tag })
        if (tagFound) {
          flagsTags[categorie] = true
        }
      })
    })
    if (!flagsTags.secteur || !flagsTags.fonctionnelle || !flagsTags.informatique || !flagsTags.linguistique) {
      errors.push("Veuillez sélectionner au moins un tag de chaque type de compétences")
      flag = false;
    }
    if (!newOffer.idGrade.length > 0) {
      errors.push("Veuillez indiquer la séniorité que vous recherchez")
      flag = false;
    }

    if (!newOffer.idTimeexperience.length > 0) {
      errors.push("Veuillez indiquer le nombre d'années d'expérience que vous recherchez")
      flag = false;
    }

    if (newOffer.description === "") {
      errors.push("Veuillez renseigner la description de l'opportunité")
      flag = false;
    }

    setErrors(errors);
    return flag;
  }

  return (

    <div className='p-4'>
      <section className='border-b-2'>
        <h3 className='font-bold text-[26px]'>
          {newOffer.intitule}
        </h3>
      </section>
      <section className='grid-rows-2 grid-cols-2'>
        <div className="flex items-center gap-2 mb-2 mt-4">
          <MdChecklist size={28} />
          <p className="font-bold text-[22px]">Modalités</p>
        </div>
        <div className='grid grid-cols-3 grid-rows-2 gap-6'>
          {/* Contrats */}
          <div >
            <div className='flex items-center gap-2'>
              <FaFileContract size={22} />
              <p className="font-bold text-[18px]">Contrat recherché</p>
            </div>
            <div className="flex flex-wrap mt-2">
              {
                filters && filters.contrat.map((el, key) => {
                  if (newOffer.idContract.includes(`/api/contracts/${el.id}`)) {
                    return <div className="bg-[#EFF5FA] rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                  }
                })
              }
            </div>
          </div>
          {/* Type d'intervention */}
          <div>
            <div className="flex items-center gap-2">
              <FaBriefcase size={22} />
              <p className="font-bold text-[18px]">Type d'intervention</p>
            </div>
            <div className="flex flex-wrap mt-2">
              {
                filters && filters.intervention.map((el, key) => {
                  const tagFiltered = newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`)
                  if (tagFiltered.length > 0) {
                    return <div className="bg-[#EFF5FA] rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                  }
                })
              }
            </div>
          </div>
          {/* Date de début */}
          <div>
            <div className="flex items-center gap-2">
              <FaHourglassStart size={22} />
              <p className="font-bold text-[18px]">Date de début</p>
            </div>
            <div className="flex flex-wrap mt-2">
              <AvaibilityBadge avaibility={{ "disponibility": newOffer.availability[0], "availability_date": newOffer.availabilityDate }} />
            </div>
          </div>

          {/* Localisation*/}
          <div>
            <div className="flex items-center gap-2">
              <FaMapMarkerAlt size={22} />
              <p className="font-bold text-[18px]">Localisation</p>
            </div>
            <div className="flex flex-wrap mt-2">
              <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 text-[14px]">{newOffer.localisation}</div>
            </div>
          </div>

          {/* Prétentions */}
          <div>
            <div className="flex items-center gap-2">
              <FaWallet size={22} />
              <p className="font-bold text-[18px]">Prétentions</p>
            </div>
            <div className="flex flex-wrap mt-2">
              {newOffer.salaire &&
                <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 mr-2 text-[14px]">{newOffer.salaire} € salaire</div>
              }
              {newOffer.tjm &&
                <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 text-[14px] mr-2">{newOffer.tjm} € TJM</div>
              }
              {newOffer.salaire === undefined && newOffer.jtm === undefined &&
                <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 text-[14px]">Non précisé</div>
              }
            </div>
          </div>

          {/* Présentiel / télétravail */}
          <div>
            <div className="flex items-center gap-2">
              <VscRemoteExplorer size={22} />
              <p className="font-bold text-[18px]">Présentiel / télétravail</p>
            </div>
            <div className="flex flex-wrap mt-2">
              <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 mr-2 text-[14px]">{newOffer.remote}</div>
            </div>
          </div>

          {/* Présentiel / télétravail */}
          <div>
            <div className="flex items-center gap-2">
              <MdOutlineTimelapse size={22} />
              <p className="font-bold text-[18px]">Temps plein / partiel / à l'heure</p>
            </div>
            <div className="flex flex-wrap mt-2">
              {
                newOffer && newOffer.workTime && newOffer.workTime.map((el, key) => {
                  return <div className="bg-[#EFF5FA] rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el}</div>
                })
              }
            </div>
          </div>
        </div>
      </section>
      <span className="border inline-block w-full mt-8 mb-4" />
      <section>
        <div className="flex items-center gap-2 mb-2">
          <FaUserTie size={28} />
          <h3 className='text-black font-bold'>Profil recherché</h3>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
          {/* Compétence sectorielles */}
          <div>
            <p className="font-medium text-[18px]">Compétences sectorielles</p>
            <div className="flex flex-wrap mt-2 gap-2">
              {
                filters && filters.secteur.map((el, key) => {
                  const tagFiltered = newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`)
                  if (tagFiltered.length > 0) {
                    // return <div className="bg-sectoriellepastel text-sectorielledark rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                    return <TagLevel name={el.name} level={tagFiltered[0].level} type={"sectorielle"} key={key} isSmall={true} />
                  }
                })
              }
            </div>
          </div>
          {/* Compétences fonctionnelles */}
          <div>
            <p className="font-medium text-[18px]">Compétences fonctionnelles</p>
            <div className="flex flex-wrap mt-2 gap-2">
              {
                filters && filters.fonctionnelle.map((el, key) => {
                  const tagFiltered = newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`)
                  if (tagFiltered.length > 0) {
                    // return <div className="bg-fonctionnellepastel text-fonctionnelledark mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                    return <TagLevel name={el.name} level={tagFiltered[0].level} type={"fonctionnelle"} key={key} isSmall={true} />
                  }
                })
              }
            </div>
          </div>
          {/* Compétences informatique */}
          <div>
            <p className="font-medium text-[18px]">Compétences informatique</p>
            <div className="flex flex-wrap mt-2 gap-2">
              {
                filters && filters.informatique.map((el, key) => {
                  const tagFiltered = newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`)
                  if (tagFiltered.length > 0) {
                    // return <div className="bg-informatiquepastel text-informatiquedark rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                    return <TagLevel name={el.name} level={tagFiltered[0].level} type={"informatique"} key={key} isSmall={true} />
                  }
                })
              }
            </div>
          </div>
          {/* Compétences linguistiques */}
          <div>
            <p className="font-medium text-[18px]">Compétences linguistiques</p>
            <div className="flex flex-wrap mt-2 gap-2">
              {
                filters && filters.linguistique.map((el, key) => {
                  const tagFiltered = newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`)
                  if (tagFiltered.length > 0) {
                    // return <div className="bg-linguistiquepastel text-linguistiquedark rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                    return <TagLevel name={el.name} level={tagFiltered[0].level} type={"linguistique"} key={key} isSmall={true} />
                  }
                })
              }
            </div>
          </div>
          {/* Séniorité */}
          <div>
            <p className="font-medium text-[18px]">Séniorité</p>
            <div className="flex flex-wrap mt-2 gap-2">
              {
                filters && filters.grade.map((el, key) => {
                  if (newOffer.idGrade.includes(`api/grades/${el.id}`)) {
                    return <div className="bg-[#EFF5FA] rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                  }
                })
              }
            </div>
          </div>
          {/* Année d'expérience */}
          <div>
            <p className="font-medium text-[18px]">Année d'expérience</p>
            <div className="flex flex-wrap mt-2 gap-2">
              {
                filters && filters.timeExperience.map((el, key) => {
                  if (newOffer.idTimeexperience.includes(`api/time_experiences/${el.id}`)) {
                    return <div className="bg-[#EFF5FA] rounded-[5px] mr-2 py-1 px-2 text-[14px]">{el.name}</div>
                  }
                })
              }
            </div>
          </div>
        </div>
      </section>
      <span className="border inline-block w-full mt-8 mb-4" />
      <section>
        <div className="flex items-center gap-2 mb-2">
          <IoDocumentText size={28} />
          <h3 className='text-black font-bold'>Descriptif de l'opportunité</h3>
        </div>
        <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 text-[14px] mt-4">
          {parse(newOffer.description ?? "")}
        </div>
      </section>
      <div className='flex justify-end mt-4'>
        {formulaire === "nouvelle-offre" ?
          <button onClick={publicationOffre} disabled={isLoading} className="flex justify-center items-center text-center max-w-[250px] border border-[#5B9ECF] bg-[#5B9ECF] text-white hover:bg-white hover:text-[#5B9ECF] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Publier l'opportunité"}
          </button>
          :
          <button onClick={saveModif} disabled={isLoading} className="flex justify-center items-center text-center max-w-[250px] border border-[#5B9ECF] bg-[#5B9ECF] text-white hover:bg-white hover:text-[#5B9ECF] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Enregistrer les modifications"}
          </button>
        }
      </div>
      <div className='flex flex-col justify-end mt-4'>
        {errors.map((error) => {
          return <span className='text-right text-red-500 text-xs font-bold'>{error}</span>
        })}
      </div>
    </div>
  )
}

export default SummaryStep