import { createBrowserRouter } from "react-router-dom";
import App from "App";
import Home from "Pages/Statics/Home/Home";
import PublicLayout from "layout/PublicLayout";
import PrivateLayout from "layout/PrivateLayout";
import Profiles from "Pages/Statics/LesProfils/LesProfilsNew";
import Jobs from "Pages/Statics/LesOpportunite/LesOpportunite"
import NousContacter from "Pages/Statics/NousContacter/NousContacter";
import Connexion from "Pages/Statics/Connexion/Connexion";
import Inscription from "Pages/Statics/Insription/Inscription";
import Consultant from "Pages/Consultant/DevenirConsultant";
import QuiSommesNous from "Pages/Statics/QuiSommesNous/QuiSommesNous";
import Faq from "Pages/Statics/FAQ/FAQ";
import MentionsLegales from "Pages/Statics/MentionsLegales/MentionsLegales";
import Cgu from "Pages/Statics/Cgu/Cgu";
import Politique from "Pages/Statics/Politique/Politique";
import DossierCompetence from "Pages/Statics/DossierCompetences/DossierCompetences";
import StoreAdmin from "Admin/homeAdmin";
import MdpOubliee from "Pages/Statics/MdpOubliee/MdpOubliee";

import ViewOffer from "Pages/Statics/ViewOffer/ViewOffer";

import RegistrationProcess from "Pages/Statics/RegistrationProcess/RegistrationProcess";
import LastExperience from "Pages/Statics/RegistrationProcess/LastExperience/LastExperience";
import Salary from "Pages/Statics/RegistrationProcess/Salary/Salary";
import Mobility from "Pages/Statics/RegistrationProcess/Mobility/Mobility";
import Experiences from "Pages/Statics/RegistrationProcess/Experiences/Experiences";
import Formations from "Pages/Statics/RegistrationProcess/Formations/Formations";
import VisibleProfile from "Pages/Statics/RegistrationProcess/VisibleProfile/VisibleProfile";
import Skills from "Pages/Statics/RegistrationProcess/Skills/Skills";
import ContractType from "Pages/Statics/RegistrationProcess/ContractType/ContractType"
import AppTest from "Pages/UserNew/testCalendarAppoitments/AppTest";
import AppDashboard from "Pages/UserNew/Dashboard/AppDashboard";

import PersonalInformation from "Pages/UserNew/PersonalInformation/PersonalInformation";
import GeneralsInformation from "Pages/UserNew/PersonalInformation/components/GeneralInformation/GeneralsInformation";
import PersonalSkills from "Pages/UserNew/PersonalInformation/components/PersonalSkills/PersonalSkills";
import PersonalFormations from "Pages/UserNew/PersonalInformation/components/PersonalFormations/PersonalFormations";
import PersonalExperiences from "Pages/UserNew/PersonalInformation/components/PersonalExperiences/PersonalExperiences";
import Privacy from "Pages/UserNew/PersonalInformation/components/Privacy/Privacy";
import PersonalModality from "Pages/UserNew/PersonalInformation/components/GeneralInformation/PersonalModality";

import AppCandidature from "Pages/UserNew/MesCandidature/AppCandidature";
import Visio from "Pages/Statics/Visio/Visio";
import Offers from "Pages/ClientNew/Offers/Offers";
import IndexOffre from "Pages/ClientNew/Offers/IndexOffre/IndexOffre";
import CreateOrEditOffer from "Pages/ClientNew/Offers/CreateOrEditOffer.jsx/CreateOrEditOffer";
import JobTitleStep from "Pages/ClientNew/Offers/CreateOrEditOffer.jsx/steps/JobTitleStep";
import ModalityStep from "Pages/ClientNew/Offers/CreateOrEditOffer.jsx/steps/ModalityStep";
import ProfileStep from "Pages/ClientNew/Offers/CreateOrEditOffer.jsx/steps/ProfileStep";
import JobDescription from "Pages/ClientNew/Offers/CreateOrEditOffer.jsx/steps/JobDescription";
import DetailOffre from "Pages/ClientNew/Offers/DetailOffre/DetailOffre";
import SummaryStep from "Pages/ClientNew/Offers/CreateOrEditOffer.jsx/steps/SummaryStep";
import FavorisRecommandation from "Pages/ClientNew/FavorisRecommandation/FavorisRecommandation";
import MdpReinitialiser from "Pages/Statics/MdpOubliee/Reinitialiser";
import EditOffer from "Pages/ClientNew/Offers/CreateOrEditOffer.jsx/EditOffer";
import RendezVous from "Pages/Consultant/RendezVous";
import RendezVousAssurance from "Pages/Consultant/RendezVousAssurance";
import TarifHonoraires from "Pages/Statics/TarifHonoraires/TarifHonoraires";
import Cab from "Pages/Statics/CAB/Cab";
import NotFound from "Pages/Statics/NotFound/NotFound";
import RegistrationGoogle from "Pages/Statics/RegistrationGoogle/RegistrationGoogle";
import Supra from "Pages/Statics/RegistrationProcess/Supra/Supra";
import RefCom from "Pages/Statics/RefCom";
import Hub from "Pages/Statics/Hub/Hub";



const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                element: <PublicLayout />,
                children: [
                    {
                        path: "",
                        element: <Home />
                    },
                    {
                        path: "les-profils",
                        element: <Profiles />
                    },
                    {
                        path: "les-opportunites/:id?",
                        element: <Jobs />
                    }, {
                        path: "offre/:id?",
                        element: <ViewOffer />
                    },
                    {
                        path: "contact",
                        element: <NousContacter />
                    },
                    {
                        path: "connexion",
                        element: <Connexion />
                    },
                    {
                        path: "inscription",
                        element: <Inscription />
                    },
                    {
                        path: "inscription-google",
                        element: <RegistrationGoogle />
                    },
                    {
                        path: "devenir-consultant",
                        element: <Consultant />
                    },
                    {
                        path: "references-commerciales",
                        element: <RefCom />
                    },
                    {
                        path: 'qui-somme-nous',
                        element: <QuiSommesNous />
                    },
                    {
                        path: 'FAQ',
                        element: <Faq />
                    },
                    {
                        path: 'mentions-legales',
                        element: <MentionsLegales />

                    },
                    {
                        path: 'CGU',
                        element: <Cgu />
                    },
                    {
                        path: 'RendezVous',
                        element: <RendezVous />
                    },
                    {
                        path: 'RendezVousAssurance',
                        element: <RendezVousAssurance />
                    },
                    {
                        path: 'tarification-honoraires',
                        element: <TarifHonoraires />
                    },
                    {
                        path: 'Le-cab',
                        element: <Cab />
                    },
                    {
                        path: 'Le-HUB',
                        element: <Hub />
                    },
                    {
                        path: "politique-de-confidentialite",
                        element: <Politique />
                    },

                    {
                        path: "dossier-de-competences/:id",
                        element: <DossierCompetence />
                    },
                    {
                        path: "parcours-inscription",
                        element: <RegistrationProcess />,
                        children: [
                            {
                                path: "",
                                element: <LastExperience />
                            },
                            {
                                path: "type-contrat",
                                element: <ContractType />
                            },
                            {
                                path: "mobilite",
                                element: <Mobility />
                            },
                            {
                                path: "formations",
                                element: <Formations />
                            },
                            {
                                path: "experiences",
                                element: <Experiences />
                            },
                            {
                                path: "supra",
                                element: <Supra />
                            },
                            {
                                path: "remuneration",
                                element: <Salary />
                            }, {
                                path: "competences",
                                element: <Skills />
                            }, {
                                path: "visibilite",
                                element: <VisibleProfile />
                            },

                        ]
                    },
                    {
                        path: "oublier-password",
                        element: <MdpOubliee />
                    },
                    {
                        path: "reinitialiser-password/:token",
                        element: <MdpReinitialiser />
                    },
                    {
                        path: "*",
                        element: <NotFound />
                    },
                ]
            },
            {
                path: "mon-espace",
                element: <PrivateLayout />,
                children: [
                    {
                        path: "mes-entretiens/:id?",
                        element: <AppTest />
                    },
                    {
                        path: "Dashboard",
                        element: <AppDashboard />
                    },
                    {
                        path: "mon-profil",
                        element: <PersonalInformation />,
                        children: [
                            {
                                path: "informations-generales/:modal?",
                                element: <GeneralsInformation />
                            },
                            {
                                path: "modalites",
                                element: <PersonalModality />,
                            },
                            {
                                path: "competences",
                                element: <PersonalSkills />,
                            },
                            {
                                path: "confidentialite",
                                element: <Privacy />,
                            },
                            {
                                path: "experiences/:action?/:id?",
                                element: <PersonalExperiences />
                            },
                            {
                                path: "formations/:action?/:id?",
                                element: <PersonalFormations />
                            }
                        ]
                    },
                    {
                        path: "mes-offres",
                        element: <Offers />,
                        children: [
                            {
                                path: "",
                                element: <IndexOffre />,
                            },
                            {
                                path: "detail/:id",
                                element: <DetailOffre />
                            },
                            {
                                path: "nouvelle-offre",
                                element: <CreateOrEditOffer />,
                                children: [
                                    {
                                        path: "",
                                        element: <JobTitleStep />
                                    },
                                    {
                                        path: "modalite",
                                        element: <ModalityStep />
                                    },
                                    {
                                        path: "profil",
                                        element: <ProfileStep />
                                    },
                                    {
                                        path: "description",
                                        element: <JobDescription />
                                    },
                                    {
                                        path: "recapitulatif",
                                        element: <SummaryStep />
                                    },
                                ]
                            },
                            {
                                path: "modification-offre/:id",
                                element: <EditOffer />,
                                children: [
                                    {
                                        path: "",
                                        element: <JobTitleStep />
                                    },
                                    {
                                        path: "modalite",
                                        element: <ModalityStep />
                                    },
                                    {
                                        path: "profil",
                                        element: <ProfileStep />
                                    },
                                    {
                                        path: "description",
                                        element: <JobDescription />
                                    },
                                    {
                                        path: "recapitulatif",
                                        element: <SummaryStep />
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        path: "favoris-recommendation",
                        element: <FavorisRecommandation />
                    },
                    {
                        path: "mes-candidatures/:id?",
                        element: <AppCandidature />
                    },
                    {
                        path: "*",
                        element: <NotFound />
                    },
                ]
            },
            {
                path: "iris/*",
                element: <StoreAdmin />
            },
            // {
            //     path: "test",
            //     element:
            // }
            // ,
            {
                path: "visio/:id",
                element: <Visio />
            },
        ]
    },
]);

export default router