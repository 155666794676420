import { useState } from 'react'
import CreateOrEditOfferNav from './CreateOrEditOfferNav'
import { Outlet, useOutletContext, useNavigate } from 'react-router-dom'
import { FaCheck } from "react-icons/fa";


function CreateOrEditOffer() {
  const [filters] = useOutletContext();
  const formulaire = "nouvelle-offre";
  const navigation = useNavigate()
  const [step, setStep] = useState({
    intituler: false,
    modalite: false,
    profile: false,
    description: false
  })


  const navigateHandler = (e) => {
    navigation(`/mon-espace/mes-offres/nouvelle-offre/${e.target.value}`)
  }

  const [newOffer, setNewOffer] = useState({
    intitule: "",
    entrepriseOffre: "",
    idContract: [],
    nameContract: [],
    tags: [],
    nameTag: [],
    nameTagIntervention: [],
    availability: [],
    availabilityDate: "",
    salaire: null,
    tjm: null,
    localisation: "",
    description: "",
    idTimeexperience: [],
    nameTimeexperience: [],
    idGrade: [],
    nameGrade: [],
    remote: "",
    workTime: []
  })

  return (
    <div className='flex gap-4 p-6 md:items-start flex-col md:flex-row xl:justify-center'>
      <CreateOrEditOfferNav step={step} />
      <select onChange={navigateHandler} className='md:hidden w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none' name="" id="">
        <option value="">Intitulé</option>
        <option value="modalite">Modalité</option>
        <option value="profil">Profil</option>
        <option value="description">Description</option>
        <option value="recapitulatif">Récapitulatif</option>
      </select>
      <div className='bg-white flex-1 lg:max-w-[800px]'>
        <Outlet context={[step, setStep, newOffer, setNewOffer, filters, formulaire]} />
      </div>
    </div>
  )
}

export default CreateOrEditOffer