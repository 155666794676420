import { useEffect, useContext, useState } from "react"
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import Checkbox from 'Components/Forms/CheckBox/Checkbox';
import Badge from 'Components/Badge/Badge'

function ProfileStep() {
  const navigate = useNavigate();
  const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  const [searchField, setSearchField] = useState("");
  const { id } = useParams();
  const [step, setStep, newOffer, setNewOffer, filters, formulaire] = useOutletContext();
  const [selectedSignet, setSelectedSignet] = useState("secteur");
  const [tagsError, setTagsError] = useState();
  const [timeExperienceErr, setTimeExperienceErr] = useState(null);
  const [gradeErr, setGradeErr] = useState(null);
  const [validationErr, setValidationErr] = useState({
    fonctionnelle: [],
    secteur: [],
    informatique: [],
    linguistique: []
  })
  const [offerSkills, setOfferSkills] = useState({
    secteur: [],
    fonctionnelle: [],
    informatique: [],
    linguistique: [],
  });

  const handleSkill = (e, skillCategory) => {
    function isTagVerify(value) {
      return value.tag === e.target.value;
    }
    setTagsError(null)
    if (newOffer.tags.find(isTagVerify)) {
      setNewOffer(prev => ({ ...prev, tags: prev.tags.filter(x => x.tag !== e.target.value) }))
      setOfferSkills(prev => ({ ...prev, [selectedSignet]: prev[selectedSignet].filter(el => el !== e.target.value) }))
      //setValidationErr(prev => ({ ...prev, [skillCategory]: prev[skillCategory].filter(x => x !== e.target.value) }))
    } else {
      if (offerSkills[selectedSignet].length < 10) {
        //console.log(id)
        setNewOffer(prev => ({
          ...prev, tags: [...prev.tags, {
            offre: `api/offres/${id}`,
            tag: e.target.value,
            level: 1
          }]
        }))
        setOfferSkills(prev => ({ ...prev, [selectedSignet]: [...prev[selectedSignet], e.target.value] }))
        //setValidationErr(prev => ({ ...prev, [skillCategory]: [...prev[skillCategory], e.target.value] }))
      }
    }
    setSearchField("")
  }

  function handleLevel(e, level) {
    setNewOffer(prev => ({ ...prev, tags: prev.tags.filter(x => x.tag !== e) }))
    setNewOffer(prev => ({
      ...prev, tags: [...prev.tags, {
        offre: `api/offres/${id}`,
        tag: e,
        level: level
      }]
    }))
  }


  const handleTimeExperience = (e) => {
    setTimeExperienceErr(null)
    if (newOffer.idTimeexperience.includes(e.target.value)) {
      setNewOffer(prev => ({ ...prev, idTimeexperience: prev.idTimeexperience.filter(x => x !== e.target.value) }))
    } else {
      setNewOffer(prev => ({ ...prev, idTimeexperience: [...prev.idTimeexperience, e.target.value] }))
    }
  }


  const handleIdGrade = (e) => {
    setGradeErr(null)
    if (newOffer.idGrade.includes(e.target.value)) {
      setNewOffer(prev => ({ ...prev, idGrade: prev.idGrade.filter(x => x !== e.target.value) }))
    } else {
      setNewOffer(prev => ({ ...prev, idGrade: [...prev.idGrade, e.target.value] }))
    }
  }

  const handleSignet = (id) => {
    setSelectedSignet(id)
  }


  const next = () => {
    var flagsTags = { "secteur": false, "fonctionnelle": false, "informatique": false, "linguistique": false }
    newOffer.tags.map((tagOffer) => {
      ["secteur", "fonctionnelle", "informatique", "linguistique"].map((categorie) => {
        const tagFound = filters[categorie].some((tagFilter) => { return `api/tags/${tagFilter.id}` === tagOffer.tag })
        if (tagFound) {
          flagsTags[categorie] = true
        }
      })
    })

    if (!flagsTags.secteur || !flagsTags.fonctionnelle || !flagsTags.informatique || !flagsTags.linguistique) {
      setTagsError("Séléctionner au moins un tag de chaque competence.")
      return
    }
    if (!newOffer.idGrade.length > 0) {
      setGradeErr("Veuillez selectionner un champ.")
      return
    }

    if (!newOffer.idTimeexperience.length > 0) {
      setTimeExperienceErr("Veuillez selectionner un champ.")
      return
    }
    setStep(prev => ({ ...prev, profil: true }))
    navigate(`/mon-espace/mes-offres/${formulaire}/description`)
  }


  const prev = () => {

    navigate(`/mon-espace/mes-offres/${formulaire}/modalite`)
  }

  useEffect(() => {
    if (filters !== undefined && newOffer.tags !== undefined) {
      const { informatique, secteur, fonctionnelle, linguistique } = filters
      //Structure des secteur
      secteur.map((sec) => {
        const filterCompetence = newOffer.tags.find((e) => sec.id == e.tag.split("/")[2]);
        if (filterCompetence !== undefined) {
          setOfferSkills(prev => ({ ...prev, secteur: [...prev.secteur, `api/tags/${filterCompetence}`] }))
        }
      })
      setOfferSkills(prev => ({
        ...prev, secteur: prev.secteur.filter(function (elem, pos) {
          return prev.secteur.indexOf(elem) == pos;
        })
      }))
      //Structure des fonction
      fonctionnelle.map((sec) => {
        const filterCompetence = newOffer.tags.find((e) => sec.id == e.tag.split("/")[2]);
        if (filterCompetence !== undefined) {
          setOfferSkills(prev => ({ ...prev, fonctionnelle: [...prev.fonctionnelle, `api/tags/${filterCompetence}`] }))
        }
      })
      setOfferSkills(prev => ({
        ...prev, fonctionnelle: prev.fonctionnelle.filter(function (elem, pos) {
          return prev.fonctionnelle.indexOf(elem) == pos;
        })
      }))
      //Structure des informatique
      informatique.map((sec) => {
        const filterCompetence = newOffer.tags.find((e) => sec.id == e.tag.split("/")[2]);
        if (filterCompetence !== undefined) {
          setOfferSkills(prev => ({ ...prev, informatique: [...prev.informatique, `api/tags/${filterCompetence}`] }))
        }
      })
      setOfferSkills(prev => ({
        ...prev, informatique: prev.informatique.filter(function (elem, pos) {
          return prev.informatique.indexOf(elem) == pos;
        })
      }))
      //Structure des langue
      linguistique.map((sec) => {
        const filterCompetence = newOffer.tags.find((e) => sec.id == e.tag.split("/")[2]);
        if (filterCompetence !== undefined) {
          setOfferSkills(prev => ({ ...prev, linguistique: [...prev.linguistique, `api/tags/${filterCompetence}`] }))
        }
      })
      setOfferSkills(prev => ({
        ...prev, linguistique: prev.linguistique.filter(function (elem, pos) {
          return prev.linguistique.indexOf(elem) == pos;
        })
      }))
    }
  }, [filters, newOffer.id_tags])

  return (
    <div className='max-w-[880px] p-4 space-y-4'>
      <div className='grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6'>
        <div className='col-span-6 xl:col-span-3'>
          {timeExperienceErr && <span className='col-span-2 text-red-500 text-xs font-bold'>{timeExperienceErr}</span>}
          <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Année d'expérience*</label>
          <div className='grid grid-cols-2'>
            {
              filters ?
                filters.timeExperience.map((el, key) => (
                  <div className='flex gap-1 items-center'>
                    <input onChange={(e) => handleTimeExperience(e)} checked={newOffer.idTimeexperience.includes(`api/time_experiences/${el.id}`)} value={`api/time_experiences/${el.id}`} className='cursor-pointer' type={"checkbox"} key={el.id} id={el.id} />
                    <label className='cursor-pointer' htmlFor={el.id}>{el.name}</label>
                  </div>
                ))
                :
                null
            }
          </div>
        </div>
        <div className='col-span-6 xl:col-span-3'>
          {gradeErr && <span className='col-span-2 text-red-500 text-xs font-bold'>{gradeErr}</span>}
          <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Séniorité*</label>
          <div className='grid grid-cols-2'>
            {
              filters ?
                filters.grade.map((el, key) => (
                  <div className='flex gap-1 items-center'>
                    <input className='cursor-pointer' onChange={(e) => handleIdGrade(e)} checked={newOffer.idGrade.includes(`api/grades/${el.id}`)} type={"checkbox"} value={`api/grades/${el.id}`} key={el.id} id={`idGrade/${el.id}`} />
                    <label className='cursor-pointer' htmlFor={`idGrade/${el.id}`}>{el.name}</label>
                  </div>
                ))
                :
                null
            }
          </div>
        </div>
      </div>
      <div className="pt-4 mb-4">
        <div className='flex gap-4 mb-3 border-b pb-2 flex-wrap'>
          <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "secteur" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("secteur")}>
            {/* <span className={`group-hover:text-white group-hover:bg-[#2563EB] ${selectedSignet === "sectorialSkills" ? "text-[#FFF] bg-[#2563EB]"  : " text-[#9C9B9F]"} py-1 px-2 bg-[#F7F9FC] rounded text-[#9C9B9F] mr-2`}>2</span> */}
            Sectorielles
          </p>
          <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "fonctionnelle" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("fonctionnelle")}>
            {/* <span className={`group-hover:text-white group-hover:bg-[#2563EB] ${selectedSignet === "fonctionalSkills" ? "text-[#FFF] bg-[#2563EB]"  : " text-[#9C9B9F]"} py-1 px-2 bg-[#F7F9FC] rounded text-[#9C9B9F] mr-2`}>1</span> */}
            Fonctionnelles
          </p>
          <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "informatique" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("informatique")}>
            {/* <span className={`group-hover:text-white group-hover:bg-[#2563EB] ${selectedSignet === "computerSkills" ? "text-[#FFF] bg-[#2563EB]"  : " text-[#9C9B9F]"} py-1 px-2 bg-[#F7F9FC] rounded text-[#9C9B9F] mr-2`}>6</span> */}
            Informatiques
          </p>
          <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "linguistique" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("linguistique")}>
            Linguistiques
          </p>
          <div className='w-full'>
            <input placeholder='recherche' name='recherche' id='recherche' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" value={searchField} onChange={(e) => setSearchField(e.target.value)} />
          </div>
        </div>
        <h5 className='text-lg flex gap-3 mb-2'>
          <Badge mode="info" name="min : 1" />
          <p className='w-fit text-sm text-black bg-[#E2E2E2] px-[5px] rounded-lg'>Compte : {offerSkills[selectedSignet].length}</p>
          <Badge mode="warning" name="max : 10" />
          <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'>Sélectionner votre niveau de compétence :</p>
          <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 1 _ : Connaissance </p>
          <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 2 _ _  : Pratique </p>
          <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 3 _ _ _ _ : Maitrise </p>
          <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 4 _ _ _ _ : Expert </p>
        </h5>
        <div className="flex flex-wrap gap-1">
          {filters ?
            filters[selectedSignet].filter(x => removeAccents(x.name.toLowerCase().replace(/\/ /g, '')).includes(removeAccents(searchField.toLowerCase().replace(/\/ /g, '')))).map((el, key) => {
              var type = "";
              switch (selectedSignet) {
                case "secteur":
                  type = "sectorialSkills";
                  break;
                case "fonctionnelle":
                  type = "fonctionalSkills";
                  break;
                case "informatique":
                  type = "computerSkills";
                  break;
                case "linguistique":
                  type = "languageSkills";
                  break;
              }
              return <Checkbox
                tagLevel={type}
                key={el.id}
                id={el.id}
                onChange={(e) => handleSkill(e, selectedSignet)}
                handleLevel={handleLevel}
                checked={newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`).length > 0}
                level={newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`).length > 0 ? newOffer.tags.filter(item => item.tag === `api/tags/${el.id}`)[0].level : 1}
                name={el.name}
                value={`api/tags/${el.id}`}
                label={el.name} />
            })
            :
            null
          }
        </div>
        {tagsError && <span className='col-span-2 text-red-500 text-xs font-bold'>{tagsError}</span>}
      </div>
      <div className='sm:col-span-6 flex justify-between'>
        <SecondaryButton type={"submit"} text={"Précedent"} onClick={() => prev()} />
        <PrimaryButton type={"submit"} text={"Suivant"} onClick={() => next()} />
      </div>
    </div>
  )
}

export default ProfileStep